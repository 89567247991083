<template>
  <AdapterFrame>
    <div class="baseBox">
      <div style="padding-top: 10px;text-align: center;font-size: larger;color: white">
        <span>Diagnostic Report</span>
      </div>
      <div class="baseContentBox">
        <div class="contentBox">
          <div class="titleBox">
            <div class="fdjImg">
              <img style="width: 50px;display: inline-block" src="../image/fdj.png" alt="" />
              <span style="padding-left: 20px">Fault Code</span>
            </div>
            <div>{{ count }}个</div>
          </div>
          <van-list style="padding-top: 30px">
            <div
                    class="cardList"
                    v-for="(item) in list"
                    :key="item.key"
            >
              <div class="cardItemBox">
                <div class="cardItem">
                  <div class="cardTit">
                    <div>{{ item.shortCode }}</div>
                    <div>{{ item.codeTypeName }}</div>
                  </div>
                  <div class="cardContent">
                    {{item.codeTypeContent}}
                  </div>
                  <div class="cardDesc">
                    {{item.desc}}
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </div>
        <div style="padding-left: 20px">
          <span>Time: {{currenTime}}</span>
        </div>
      </div>
      <div v-if="baseLoading" class="uploadLoadingBox">
        <van-loading size="24px" vertical color="#0094ff">Analyzing, please wait…</van-loading>
      </div>
    </div>
  </AdapterFrame>
</template>

<script>
  import AdapterFrame from '@/components/AdapterFrame.vue';
  import axios from "axios";
  import Vue from 'vue';
  import { Loading } from 'vant-green';
  Vue.use(Loading);
  export default {
    name: 'Login',
    components: {
      AdapterFrame,
    },
    data() {
      return {
        currenTime: '',
        jsonData: {},
        typeCodeJson: {},
        list: [],
        loading: false,
        finished: false,
        count: 0,
        dictCode: {
          "03": "Current DTC",
          "07": "Pending DTC",
          "10": "Permanent DTC"
        },
        baseLoading: false,
        checkPrefixAddr: ['P1','P30','P31','P32','P33','C1','C2','B1','B2','U1','U2']
      };
    },
    created(){
      this.openLoading()
      this.readDict()
    },
    methods: {
      init(param){
        let split = param.split("/");
        var shortCodeStr = split[0]
        var codeTypeStr = split[2]
        var prefixCode = parseInt(split[3])
        this.count = parseInt(split[1])
        var codeTypeContent = this.dictCode[codeTypeStr]
        // 参数分隔处理
        this.list = []
        var paramAddr = []
        var start = 0
        for (let i = 0; i < this.count; i++) {
          paramAddr.push(shortCodeStr.substring(start, 5*(i+1)))
          start = start + 5
        }

        var params = []
        for (let i = 0; i < paramAddr.length; i++) {
          var curItemKey = paramAddr[i]
          params.push(curItemKey)
        }
        var this_ = this
        axios.post(process.env.VUE_APP_API_URL+'/api/bt/btBycodes', params, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
                .then(function (res) {
                  for (let i = 0; i < paramAddr.length; i++) {
                    var item = paramAddr[i]

                    var checkFlag = false
                    this_.checkPrefixAddr.forEach(curItem => {
                      if(item.startsWith(curItem)){
                        checkFlag = true
                      }
                    })

                    var codeTypeName = this_.typeCodeJson[split[3]]
                    var itemKey = item
                    if(!checkFlag){
                      // 车家自定义码
                      codeTypeName = 'Generic'
                    }
                    var codeTypeNameTemp = ''
                    if(checkFlag && prefixCode == 0){
                      codeTypeName = ''
                      codeTypeNameTemp = 'Manufacturer control'
                    }

                    var itemData = res.data[itemKey]
                    if(itemData == undefined || itemData == null){
                      var desc = "The vehicle's code is defined by the manufacture, please back to select another."
                      if(!checkFlag){
                        desc = "The fault code is not found in the database"
                        codeTypeName = ''
                      }

                      if(codeTypeNameTemp != ''){
                        desc = codeTypeNameTemp;
                      }

                      itemData = {
                        "code": itemKey,
                        "shortCode": item,
                        "desc": desc
                      }
                    }else{
                      if(checkFlag && prefixCode == 0){
                        itemData = {
                          "code": itemKey,
                          "shortCode": item,
                          "desc": 'Manufacturer control'
                        }
                      }
                    }
                    var data = {
                      "codeTypeContent": codeTypeContent,
                      "codeTypeName": codeTypeName,
                      "code": itemData.code,
                      "shortCode": itemData.shortCode,
                      "desc": itemData.desc
                    }
                    this_.list.push(data)
                  }
                })
                .catch(function (error) {
                  // 处理错误的情况
                  console.error(error);
                });


        var currentTime = new Date();
        var currentYear = currentTime.getFullYear();
        var currentMonth = currentTime.getMonth() + 1; // 月份从0开始，需要加1
        var currentDay = currentTime.getDate();
        var currentHour = currentTime.getHours();
        var currentMinute = currentTime.getMinutes();
        var currentSecond = currentTime.getSeconds();
        this.currenTime = currentYear + "-" + currentMonth + "-" + currentDay + " " + currentHour + ":" + currentMinute + ":" + currentSecond
        this.baseLoading = false
      },
      // loading
      openLoading() {
        this.baseLoading = true;
      },
      closeLoading() {
        var this_ = this
        setTimeout(function (){
          this_.baseLoading = false;
        }, 3000)
      },
      readDict(){
        axios.get('/typeCode.json')
                .then(result => {
                  this.typeCodeJson = result.data
                  //  获取参数
                  var param = this.$route.query.t
                  // var this_ = this
                  this.init(param)
                  // setTimeout(function (){
                  //   this_.init(param)
                  // }, 3000)

                })
                .catch(error => {
                  console.log(error)
                })
      }
    },
  };
</script>

<style scoped>
  .customPassword ::v-deep input {
    -webkit-text-security: disc;
  }
  .baseBox {
    /*background-color: #e6a75d;*/
    background-image: url("../image/background.png");
    background-position: center;
    background-size: cover;
    padding: 10px 0px;
    /*height: 100px;*/
  }
  .baseContentBox{
    background-color: white;
    margin: 10px 15px 0px 15px;
  }
  .contentBox{
    padding: 20px 20px 0px 20px;
  }
  .contentBox .cardItemBox{
    padding-bottom: 20px;
  }
  .cardItemBox .cardTit{
    color: #d6ab68;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
  }
  .titleBox{
    font-size: 18px;
    display: flex;
    justify-content: space-between;
  }
  .cardItemBox .cardContent{
    color: black;
    font-size: 16px;
  }
  .cardItemBox .cardDesc{
    color: #8c8e8d;
    font-size: 14px;
  }
  /* loading */
  .uploadLoadingBox {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.6);
    z-index: 5;
  }
</style>
s
